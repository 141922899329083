import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBr from "date-fns/locale/pt-BR";
import InputMask from 'react-input-mask';
import moment from 'moment';
import CustomInput from './CustomInput';
import CustomInputLabelExterno from './CustomInputLabelExterno';

export default function Data({ id = null, inputName = null, label = "Data", value, onChange = null, placeholder = "Data", title = "", readOnly = false, disabled = null, labelExterno = false, className = "" }) {
    var selected = moment(value, "DD/MM/YYYY");
    if (selected.isValid()) {
        selected = selected.toDate();
    } else {
        selected = null;
    }
    return (
        <DatePicker
            disabled={disabled}
            id={id}
            title={title}
            readOnly={readOnly}
            value={value}
            selected={selected}
            locale={ptBr}
            onChangeRaw={(event) => {
                onChange(event.target.value);
            }}
            onChange={(data) => {
                onChange(moment(data).format('DD/MM/YYYY'));
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText={label}
            popperPlacement="top-end"
            customInput={labelExterno ?
                <CustomInputLabelExterno
                    disabled={disabled}
                    id={id}
                    inputName={inputName}
                    label={label}
                    readOnly={readOnly}
                    title={title}
                    placeholder={placeholder}
                    className={className}
                /> : <CustomInput
                    disabled={disabled}
                    id={id}
                    inputName={inputName}
                    label={label}
                    readOnly={readOnly}
                    title={title}
                    placeholder={placeholder}
                    className={className}
                />
            }
        />
    );
}
