import React, { useState, useEffect, useMemo } from 'react';
import ErpTemplate from '../../components/ErpTemplate';
import { FaList, FaUpload, FaCalendarAlt, FaPen, FaPlus, FaSearch, FaThumbsUp, FaUsers, FaTable } from 'react-icons/fa';
import { BsCardChecklist } from 'react-icons/bs';
import { GrMail } from "react-icons/gr";
import { ImCancelCircle } from 'react-icons/im';
import { GiOpenChest } from 'react-icons/gi';
import NavItem from '../../components/NavItem';
import IconButton from '../../components/IconButton';
import Alert from '../../components/Alert';
import api from '../../services/api';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Data from '../../components/Data';
import { dataMysql } from '../../util/util';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import ReactSelect from '../../components/ReactSelect';
import { toast } from 'react-toastify';
import FormButton from '../../components/FormButton';

export default function PainelBriefingCliente() {
    const token = localStorage.getItem('token');
    const empresa = localStorage.getItem("empresa");
    const perfil = localStorage.getItem("perfil");
    const params = new URLSearchParams(window.location.search);

    return (
        <ErpTemplate sidebar={false}>
            <div className='imagemFundoEvento' style={{ backgroundImage: "url(/imagens/evento.png)" }}>
                <span className="mask bg-primary"></span>
            </div>
            <div className="main-content container-fluid">
                <section className="section">
                    <div className="conjuntoBoxBriefing">
                        <div className="boxBriefing">
                            <p className="boxBriefing-text">Clique no botão abaixo para preencher um novo briefing de um evento</p>
                            <div className="boxBriefing-button">
                                <IconButton
                                    style={{ backgroundColor: "white" }}
                                    to="/cadastroModeloBriefing" icon={<FaPlus size={20} />} color="default">Novo Briefing</IconButton>
                            </div>
                        </div>
                        <div className="boxBriefing">
                            <p className="boxBriefing-text">Clique para visualisar os briefings já preenchidos</p>
                            <div className="boxBriefing-button">
                                <IconButton
                                    style={{ backgroundColor: "white" }}
                                    to="/listaBriefingCliente" icon={<FaList size={20} />} color="default">Briefings</IconButton>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}
