import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMysql } from '../../../util/util';
import ReactSelect from '../../../components/ReactSelect';
import NavItem from '../../../components/NavItem';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

export default function CadastroEventoParticipanteLista({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [dadosEvento, setDadosEvento] = useState({});
    const [processando, setProcessando] = useState(false);
    const [camposQuestionario, setCamposQuestionario] = useState({});
    const history = useHistory();
    let { state } = useLocation();
    var idEvento = state?.evento ?? null;

    const buscar = () => {
        if (idEvento) {
            api({
                method: "get",
                action: '/evento/' + idEvento,
                token: token,
            }).then(responseEvento => {
                let dadosRecebidos = { ...responseEvento.data.data };

                dados.mostrarTermo = false; //nunca mostrar termo
                dados.validarTudo = false;
                if (dadosRecebidos.termoaceiteassinatura && dadosRecebidos.termoaceiteassinatura !== '') { //se evento tiver termo, mostrar
                    dados.mostrarTermo = true;
                    dados.validarTudo = true;
                }

                setDados({
                    ...dados,
                    ...{
                        evento: idEvento,
                        tipoCadastro: 'participante',
                        origemregistro: 'evento',
                    }
                });
                setDadosEvento(dadosRecebidos);

                var camposQuestionarioRecebidos = dadosRecebidos.camposQuestionario ?
                    JSON.parse(dadosRecebidos.camposQuestionario) :
                    {
                        "tipoparticipante": "sim",
                        "nome": "sim",
                        "assinaturaListaPresenca": "sim",
                        "telefone": "sim",
                        "email": "sim",
                        "especialidade": "sim",
                        "rg": "sim",
                        "crm": "sim",
                        "crmuf": "sim",
                        "datanascimento": "sim",
                        "cpf": "sim",
                        "enderecologradouro": "sim",
                        "endereconumero": "sim",
                        "enderecocomplemento": "sim",
                        "enderecocep": "sim",
                        "enderecobairro": "sim",
                        "aereomunicipioembarque": "nao",
                        "enderecomunicipionome": "sim",
                        "enderecomunicipiouf": "sim",
                    };
                setCamposQuestionario(camposQuestionarioRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if idEvento
    };//buscar

    const validar = () => {
        if ((camposQuestionario.tipoparticipante ?? "nao") === "sim") {
            var tipoparticipante = document.querySelector("#tipoparticipante");
            if ((dados.tipoparticipante ?? '') === '') {
                toast.error('É necessário preencher o tipo participante');
                setTimeout(() => {
                    tipoparticipante.focus();
                }, 500);
                return false;
            }
        }

        // Validação específica para tipos 5, 6 e 7
        var nome = document.querySelector("#nome");
        var email = document.querySelector("#email");
        if (['5', '6', '7'].includes(dados.tipoparticipante)) {
            if ((camposQuestionario.nome ?? "nao") === "sim") {
                if ((dados.nome ?? '') === '') {
                    toast.error('É necessário preencher o nome');
                    setTimeout(() => {
                        nome.focus();
                    }, 500);
                    return false;
                }
            }

            if ((camposQuestionario.email ?? "nao") === "sim") {
                if ((dados.email ?? '') === '') {
                    toast.error('É necessário preencher o email');
                    setTimeout(() => {
                        email.focus();
                    }, 500);
                    return false;
                }
            }

            return true; // Se passou pelas validações básicas, retorna true
        }

        // Para outros tipos de participante, aplica todas as validações
        if ((camposQuestionario.nome ?? "nao") === "sim") {
            if ((dados.nome ?? '') === '') {
                toast.error('É necessário preencher o nome');
                setTimeout(() => {
                    nome.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.instituicao ?? "nao") === "sim") {
            var instituicao = document.querySelector("#instituicao");
            if ((dados.instituicao ?? '') === '') {
                toast.error('É necessário preencher o instituição');
                setTimeout(() => {
                    instituicao.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.email ?? "nao") === "sim") {
            if ((dados.email ?? '') === '') {
                toast.error('É necessário preencher o email');
                setTimeout(() => {
                    email.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.especialidade ?? "nao") === "sim") {
            var especialidade = document.querySelector("#especialidade");
            if ((dados.especialidade ?? '') === '') {
                toast.error('É necessário preencher o especialidade');
                setTimeout(() => {
                    especialidade.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.crm ?? "nao") === "sim") {
            var crm = document.querySelector("#crm");
            if ((dados.crm ?? '') === '') {
                toast.error('É necessário preencher o CRM');
                setTimeout(() => {
                    crm.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.enderecouf ?? "nao") === "sim") {
            var enderecouf = document.querySelector("#enderecouf");
            if ((dados.enderecouf ?? '') === '') {
                toast.error('É necessário preencher o Estado');
                setTimeout(() => {
                    enderecouf.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.enderecomunicipio ?? "nao") === "sim") {
            var enderecomunicipio = document.querySelector("#enderecomunicipio");
            if ((dados.enderecomunicipio ?? '') === '') {
                toast.error('É necessário preencher o Município');
                setTimeout(() => {
                    enderecomunicipio.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.aereomunicipioembarque ?? "nao") === "sim") {
            var aereomunicipioembarque = document.querySelector("#aereomunicipioembarque");
            if ((dados.aereomunicipioembarque ?? '') === '') {
                toast.error('É necessário preencher o Município');
                setTimeout(() => {
                    aereomunicipioembarque.focus();
                }, 500);
                return false;
            }
        }

        if ((camposQuestionario.coluna1 ?? "nao") === "sim") {
            var coluna1 = document.querySelector("#coluna1");
            if ((dados.coluna1 ?? '') === '') {
                toast.error('É necessário preencher: ' + (dadosEvento.nomecoluna1 ?? "Coluna 1"));
                setTimeout(() => {
                    coluna1.focus();
                }, 500);
                return false;
            }
        }

        return true;
    };

    const salvar = () => {

        if (!validar()) {
            return false; //para codigo aqui
        }

        delete dados.hoteldatacheckin;
        delete dados.hoteldatacheckout;
        delete dados.datanascimento;
        delete dados.dataopcaoaereo;
        delete dados.datacartainformativa;
        delete dados.dataconfirmacaocartainformativa;
        delete dados.datacartaconvite;
        delete dados.datacartaassinada;
        delete dados.dataconfirmacaodados;
        delete dados.dataescolhaaereo;
        delete dados.datainscricao;

        setProcessando(true);
        api({
            method: 'post',
            action: '/participante',
            token: token,
            data: dados
        })
            .then(function (response) {
                if (['cliente'].includes(perfil)) {
                    history.push("/cadastroEventoParticipanteListaConcluido/" + idEvento);
                } else { // perfil listarsvp
                    history.push({
                        pathname: "/cadastroEventoParticipanteAssinatura/" + response.data.data.id,
                        state: {
                            evento: idEvento
                        }
                    });
                }
                setProcessando(false);
            })
            .catch(function (error) {
                setProcessando(false);
                //alert(error)
            });
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true} processando={processando}>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['listarsvp'].includes(perfil) ? <NavItem to={"/eventoParticipante/" + idEvento} icon={<FaBackward size={20} />} color="outline-dark">Participantes</NavItem> : null}
                </ul>
                <div className="page-title">
                    <h3>Participante</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            {dados.mostrarTermo ?
                                                <>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-floating form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="termoaceiteassinatura"
                                                                    placeholder="Termo de Aceite"
                                                                    style={{ minHeight: "400px" }}
                                                                    value={dadosEvento?.termoaceiteassinatura}
                                                                    readOnly={true}
                                                                />
                                                                <label htmlFor="floatingInput">Termo de Aceite</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <button type='button' className="btn btn-primary me-1 mb-1"
                                                                onClick={() => {
                                                                    history.push("/eventoParticipante/" + idEvento);
                                                                    //dados.aceitetermoassinatura = 'nao';
                                                                    //dados.mostrarTermo = false;
                                                                    //setDados({ ...dados });
                                                                }}>
                                                                <FaThumbsDown size={20} /> Recusar
                                                            </button>
                                                            <button type='button' className="btn btn-primary me-1 mb-1"
                                                                onClick={() => {
                                                                    dados.aceitetermoassinatura = 'sim';
                                                                    dados.mostrarTermo = false;
                                                                    setDados({ ...dados });
                                                                }}>
                                                                <FaThumbsUp size={20} /> Aceitar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="row">
                                                        {(camposQuestionario.nome ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome"
                                                                        value={dados.nome}
                                                                        onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">Nome</label>
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.instituicao ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control form-control-lg" id="instituicao" placeholder="Instituicao"
                                                                        value={dados.instituicao}
                                                                        onChange={e => setDados({ ...dados, ...{ "instituicao": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">Instituicao</label>
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.rg ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control form-control-lg" id="rg" placeholder="RG"
                                                                        value={dados.rg}
                                                                        onChange={e => setDados({ ...dados, ...{ "rg": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">RG</label>
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.email ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control form-control-lg" id="email" placeholder="E-mail"
                                                                        value={dados.email}
                                                                        onChange={e => setDados({ ...dados, ...{ "email": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">E-mail</label>
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.tipoparticipante ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Tipo do Participante</label>
                                                                    <ReactSelect
                                                                        id="tipoparticipante"
                                                                        value={{ value: dados.tipoparticipante, label: dados.nomeTipoparticipante }}
                                                                        onChange={e => setDados({ ...dados, ...{ "tipoparticipante": e.value, "nomeTipoparticipante": e.label } })}
                                                                        loadOptions={(input) => {
                                                                            return api({
                                                                                method: 'get',
                                                                                action: '/participanteTipo',
                                                                                token: token,
                                                                                params: { nome: input }
                                                                            })
                                                                                .then(response => {
                                                                                    let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome }));
                                                                                    return options;
                                                                                })
                                                                                .catch(function (error) {
                                                                                    //console.log(error);
                                                                                });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.crm ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control form-control-lg" id="crm" placeholder="CRM"
                                                                        value={dados.crm}
                                                                        onChange={e => setDados({ ...dados, ...{ "crm": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">CRM</label>
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.crmuf ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Estado do CRM</label>
                                                                    <ReactSelect
                                                                        id={"crmuf"}
                                                                        value={{ value: dados.crmuf, label: dados.crmuf }}
                                                                        onChange={e => setDados({ ...dados, ...{ "crmuf": e.value, "nomeUf": e.label } })}
                                                                        options={[
                                                                            { value: "AC", label: "AC" },
                                                                            { value: "AL", label: "AL" },
                                                                            { value: "AP", label: "AP" },
                                                                            { value: "AM", label: "AM" },
                                                                            { value: "BA", label: "BA" },
                                                                            { value: "CE", label: "CE" },
                                                                            { value: "DF", label: "DF" },
                                                                            { value: "ES", label: "ES" },
                                                                            { value: "GO", label: "GO" },
                                                                            { value: "MA", label: "MA" },
                                                                            { value: "MT", label: "MT" },
                                                                            { value: "MS", label: "MS" },
                                                                            { value: "MG", label: "MG" },
                                                                            { value: "PA", label: "PA" },
                                                                            { value: "PB", label: "PB" },
                                                                            { value: "PR", label: "PR" },
                                                                            { value: "PE", label: "PE" },
                                                                            { value: "PI", label: "PI" },
                                                                            { value: "RJ", label: "RJ" },
                                                                            { value: "RN", label: "RN" },
                                                                            { value: "RS", label: "RS" },
                                                                            { value: "RO", label: "RO" },
                                                                            { value: "RR", label: "RR" },
                                                                            { value: "SC", label: "SC" },
                                                                            { value: "SP", label: "SP" },
                                                                            { value: "SE", label: "SE" },
                                                                            { value: "TO", label: "TO" },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.especialidade ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control form-control-lg" id="especialidade" placeholder="Especialidade"
                                                                        value={dados.especialidade}
                                                                        onChange={e => setDados({ ...dados, ...{ "especialidade": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">Especialidade</label>
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.telefone ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <InputMask mask="(99) 99999-9999" type="text" className="form-control form-control-lg" id="telefone" placeholder="Telefone"
                                                                        value={dados.telefone}
                                                                        onChange={e => setDados({ ...dados, ...{ "telefone": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">Telefone</label>
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.enderecouf ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Estado</label>
                                                                    <ReactSelect
                                                                        id={"enderecouf"}
                                                                        value={{ value: dados.enderecouf, label: dados.enderecouf }}
                                                                        onChange={e => setDados({ ...dados, ...{ "enderecouf": e.value, "nomeUf": e.label } })}
                                                                        options={[
                                                                            { value: "AC", label: "AC" },
                                                                            { value: "AL", label: "AL" },
                                                                            { value: "AP", label: "AP" },
                                                                            { value: "AM", label: "AM" },
                                                                            { value: "BA", label: "BA" },
                                                                            { value: "CE", label: "CE" },
                                                                            { value: "DF", label: "DF" },
                                                                            { value: "ES", label: "ES" },
                                                                            { value: "GO", label: "GO" },
                                                                            { value: "MA", label: "MA" },
                                                                            { value: "MT", label: "MT" },
                                                                            { value: "MS", label: "MS" },
                                                                            { value: "MG", label: "MG" },
                                                                            { value: "PA", label: "PA" },
                                                                            { value: "PB", label: "PB" },
                                                                            { value: "PR", label: "PR" },
                                                                            { value: "PE", label: "PE" },
                                                                            { value: "PI", label: "PI" },
                                                                            { value: "RJ", label: "RJ" },
                                                                            { value: "RN", label: "RN" },
                                                                            { value: "RS", label: "RS" },
                                                                            { value: "RO", label: "RO" },
                                                                            { value: "RR", label: "RR" },
                                                                            { value: "SC", label: "SC" },
                                                                            { value: "SP", label: "SP" },
                                                                            { value: "SE", label: "SE" },
                                                                            { value: "TO", label: "TO" },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div> : null}
                                                        {(camposQuestionario.enderecomunicipio ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Cidade</label>
                                                                    <ReactSelect
                                                                        value={{ value: dados.enderecomunicipio, label: dados.nomeEnderecomunicipio }}
                                                                        onChange={e => setDados({ ...dados, ...{ "enderecomunicipio": e.value, "nomeEnderecomunicipio": e.label } })}
                                                                        loadOptions={(input) => {
                                                                            return api({
                                                                                method: 'get',
                                                                                action: '/municipio',
                                                                                token: token,
                                                                                params: {
                                                                                    nome: input,
                                                                                    uf: dados.enderecouf ?? null
                                                                                }
                                                                            })
                                                                                .then(response => {
                                                                                    let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                    return options;
                                                                                })
                                                                                .catch(function (error) {
                                                                                    //console.log(error);
                                                                                });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> : null}

                                                        {(camposQuestionario.aereomunicipioembarque ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <label>Cidade de Embarque</label>
                                                                    <ReactSelect
                                                                        value={{ value: dados.aereomunicipioembarque, label: dados.nomeAereomunicipioembarque }}
                                                                        onChange={e => setDados({ ...dados, ...{ "aereomunicipioembarque": e.value, "nomeAereomunicipioembarque": e.label } })}
                                                                        loadOptions={(input) => {
                                                                            return api({
                                                                                method: 'get',
                                                                                action: '/municipio',
                                                                                token: token,
                                                                                params: {
                                                                                    nome: input,
                                                                                    uf: dados.enderecouf ?? null
                                                                                }
                                                                            })
                                                                                .then(response => {
                                                                                    let options = response.data.data.map(registro => ({ value: registro.id, label: registro.nome + "-" + registro.uf }));
                                                                                    return options;
                                                                                })
                                                                                .catch(function (error) {
                                                                                    //console.log(error);
                                                                                });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> : null}

                                                        {(camposQuestionario.coluna1 ?? "nao") === "sim" ?
                                                            <div className="col-sm-6">
                                                                <div className="form-floating form-group">
                                                                    <input type="text" className="form-control form-control-lg" id="coluna1" placeholder={dadosEvento.nomecoluna1 ?? "Coluna 1"}
                                                                        value={dados.coluna1}
                                                                        onChange={e => setDados({ ...dados, ...{ "coluna1": e.target.value } })}
                                                                    />
                                                                    <label htmlFor="floatingInput">{dadosEvento.nomecoluna1 ?? "Coluna 1"}</label>
                                                                </div>
                                                            </div> : null}

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                            <button type="submit" className="btn btn-primary me-1 mb-1">
                                                                <FaSave size={20} /> Salvar</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate>
    );
}