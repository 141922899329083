import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown, FaTrash } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMysql, bin2hex, buscarCep, extract, bin2base64, data } from '../../../util/util';
import SignatureCanvas from 'react-signature-canvas'
import NavItem from '../../../components/NavItem';
import { toast } from 'react-toastify';
import ReactSelect from '../../../components/ReactSelect';

export default function CadastroEventoParticipanteAssinatura({ match }) {
    const idParticipante = match.params.id; // ID do participante
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const history = useHistory();
    let elementoAssinatura = {};
    const params = new URLSearchParams(window.location.search);
    const [idEvento, setIdEvento] = useState(null); // Armazenar o ID do evento quando for carregado

    // Recupera valores da URL
    const datalista = params.get('datalista');
    const tipoListaPresenca = params.get('tipoListaPresenca');

    // Estado inicial sem valores do localStorage (serão carregados após obter o ID do evento)
    const [dados, setDados] = useState({
        tipoListaPresenca: tipoListaPresenca ? parseInt(tipoListaPresenca) : 1,
        nomeTipoListaPresenca: "Evento",
        data: datalista || null,
        nomeData: datalista || null
    });

    // Efeito para aplicar valores do localStorage após o idEvento ser atualizado
    useEffect(() => {
        if (idEvento) {
            // Agora que temos o ID do evento, recuperamos os valores do localStorage
            const savedTipoListaPresenca = localStorage.getItem(`tipoListaPresenca_${idEvento}`);
            const savedNomeTipoListaPresenca = localStorage.getItem(`nomeTipoListaPresenca_${idEvento}`);
            const savedDataLista = localStorage.getItem(`datalista_${idEvento}`);
            const savedNomeDataLista = localStorage.getItem(`nomeDatalista_${idEvento}`);

            // Só atualizamos se não tiver valores da URL
            if (!tipoListaPresenca && savedTipoListaPresenca) {
                setDados(dadosAtuais => ({
                    ...dadosAtuais,
                    tipoListaPresenca: parseInt(savedTipoListaPresenca),
                    nomeTipoListaPresenca: savedNomeTipoListaPresenca || "Evento"
                }));
            }

            if (!datalista && savedDataLista) {
                setDados(dadosAtuais => ({
                    ...dadosAtuais,
                    data: savedDataLista,
                    nomeData: savedNomeDataLista || savedDataLista
                }));
            }

            // Se temos o tipo de lista no localStorage mas não temos o nome, buscamos o nome
            if (savedTipoListaPresenca && !savedNomeTipoListaPresenca) {
                api({
                    method: 'get',
                    action: '/participanteListaPresencaTipo',
                    token: token,
                    params: { id: savedTipoListaPresenca }
                }).then(response => {
                    const tipoLista = response.data.data.find(item => item.id === parseInt(savedTipoListaPresenca));
                    if (tipoLista) {
                        setDados(dadosAtuais => ({
                            ...dadosAtuais,
                            nomeTipoListaPresenca: tipoLista.nome
                        }));

                        // Salva no localStorage com ID do evento
                        localStorage.setItem(`nomeTipoListaPresenca_${idEvento}`, tipoLista.nome);
                    }
                }).catch(err => {
                    //console.log(err)
                });
            }
        }
    }, [idEvento, tipoListaPresenca, datalista, token]);

    // Efeito para tipo lista da URL
    useEffect(() => {
        if (tipoListaPresenca && idEvento) {
            api({
                method: 'get',
                action: '/participanteListaPresencaTipo',
                token: token,
                params: { id: tipoListaPresenca }
            }).then(response => {
                const tipoLista = response.data.data.find(item => item.id === parseInt(tipoListaPresenca));
                if (tipoLista) {
                    setDados(dadosAtuais => ({
                        ...dadosAtuais,
                        nomeTipoListaPresenca: tipoLista.nome
                    }));

                    // Salva no localStorage com ID do evento
                    localStorage.setItem(`tipoListaPresenca_${idEvento}`, tipoListaPresenca);
                    localStorage.setItem(`nomeTipoListaPresenca_${idEvento}`, tipoLista.nome);
                }
            }).catch(err => {
                //console.log(err)
            });
        }
    }, [tipoListaPresenca, idEvento, token]);

    const buscar = () => {
        if (idParticipante) {
            api({
                method: "get",
                action: '/participante/' + idParticipante,
                token: token,
                params: {
                    datalista: datalista,
                    tipoListaPresenca: tipoListaPresenca
                }
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'enderecopais',
                        'aereomunicipioembarque',
                        'enderecomunicipio',
                        'hotelmunicipio',
                        'banco',
                    ]
                });

                if (dadosRecebidos.base64assinatura) {
                    elementoAssinatura.fromDataURL(
                        "data:image/png;base64," + dadosRecebidos.base64assinatura,
                        { ratio: 1 }
                    );
                }

                // Extrair e armazenar o ID do evento
                if (dadosRecebidos.evento && dadosRecebidos.evento.id) {
                    const eventoId = dadosRecebidos.evento.id;
                    setIdEvento(eventoId);

                    // Recuperar valores do localStorage com o ID do evento
                    const savedTipoListaPresenca = localStorage.getItem(`tipoListaPresenca_${eventoId}`);
                    const savedNomeTipoListaPresenca = localStorage.getItem(`nomeTipoListaPresenca_${eventoId}`);
                    const savedDataLista = localStorage.getItem(`datalista_${eventoId}`);
                    const savedNomeDataLista = localStorage.getItem(`nomeDatalista_${eventoId}`);

                    dadosRecebidos.mostrarTermo = false; //nunca mostrar termo
                    if (dadosRecebidos.evento.termoaceiteassinatura && dadosRecebidos.evento.termoaceiteassinatura !== '') { //se evento tiver termo, mostrar
                        dadosRecebidos.mostrarTermo = true;
                        if (dadosRecebidos.aceitetermoassinatura) { //se participante ja estiver respondido, não mostrar mais
                            dadosRecebidos.mostrarTermo = false;
                        }
                    }

                    // Tratamento de datas seguindo ordem de prioridade
                    if (datalista) {
                        // Se veio da URL
                        dadosRecebidos.data = datalista;
                        dadosRecebidos.nomeData = datalista;
                        // Salva no localStorage com ID do evento
                        localStorage.setItem(`datalista_${eventoId}`, datalista);
                        localStorage.setItem(`nomeDatalista_${eventoId}`, datalista);
                    } else if (savedDataLista) {
                        // Se não veio da URL mas tem no localStorage
                        dadosRecebidos.data = savedDataLista;
                        dadosRecebidos.nomeData = savedNomeDataLista || savedDataLista;
                    } else {
                        // Se não tem nem URL nem localStorage
                        const dataEvento = data(dadosRecebidos.evento.data);
                        dadosRecebidos.data = dataEvento;
                        dadosRecebidos.nomeData = dataEvento;
                        // Salva no localStorage com ID do evento
                        localStorage.setItem(`datalista_${eventoId}`, dataEvento);
                        localStorage.setItem(`nomeDatalista_${eventoId}`, dataEvento);
                    }

                    // Tratamento do tipo de lista seguindo ordem de prioridade
                    if (tipoListaPresenca) {
                        // Se veio da URL
                        dadosRecebidos.tipoListaPresenca = parseInt(tipoListaPresenca);

                        api({
                            method: 'get',
                            action: '/participanteListaPresencaTipo',
                            token: token,
                            params: { id: tipoListaPresenca }
                        }).then(response => {
                            const tipoLista = response.data.data.find(item => item.id === parseInt(tipoListaPresenca));
                            if (tipoLista) {
                                dadosRecebidos.nomeTipoListaPresenca = tipoLista.nome;
                                // Salva no localStorage com ID do evento
                                localStorage.setItem(`tipoListaPresenca_${eventoId}`, tipoListaPresenca);
                                localStorage.setItem(`nomeTipoListaPresenca_${eventoId}`, tipoLista.nome);
                            }
                            setDados(dadosRecebidos);
                        }).catch(err => {
                            setDados(dadosRecebidos);
                        });
                    } else if (savedTipoListaPresenca) {
                        // Se não veio da URL, mas tem no localStorage
                        dadosRecebidos.tipoListaPresenca = parseInt(savedTipoListaPresenca);
                        dadosRecebidos.nomeTipoListaPresenca = savedNomeTipoListaPresenca || "Evento";
                        setDados(dadosRecebidos);
                    } else {
                        // Se não tem nem na URL nem no localStorage
                        dadosRecebidos.tipoListaPresenca = 1;
                        dadosRecebidos.nomeTipoListaPresenca = "Evento";
                        // Salva no localStorage o valor padrão com ID do evento
                        localStorage.setItem(`tipoListaPresenca_${eventoId}`, "1");
                        localStorage.setItem(`nomeTipoListaPresenca_${eventoId}`, "Evento");
                        setDados(dadosRecebidos);
                    }
                } else {
                    // Se não conseguiu determinar o ID do evento, use os valores padrão
                    setDados(dadosRecebidos);
                }
            }).catch(err => {
                //console.log(err)
            });
        }
    };

    const salvar = () => {
        if (!idEvento) {
            toast.error("ID do evento não encontrado!");
            return;
        }

        let dadosSalvar = {
            participante: dados.id,
            data: dados.data,
            tipo: dados.tipoListaPresenca,
            imagemassinatura: dados.imagemassinatura,
            binarioImagemAssinatura: bin2hex(elementoAssinatura.getCanvas().toDataURL('image/png'))
        };

        // Salva no localStorage com ID do evento
        localStorage.setItem(`tipoListaPresenca_${idEvento}`, dados.tipoListaPresenca);
        localStorage.setItem(`nomeTipoListaPresenca_${idEvento}`, dados.nomeTipoListaPresenca);
        localStorage.setItem(`datalista_${idEvento}`, dados.data);
        localStorage.setItem(`nomeDatalista_${idEvento}`, dados.nomeData);

        api({
            method: 'post',
            action: '/participanteListaPresenca',
            token: token,
            data: dadosSalvar,
        })
            .then(function (response) {
                history.push("/eventoParticipante/" + idEvento);
            })
            .catch(function (error) {
                //alert(error)
            });
    };

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <ul className="nav">
                    {['listarsvp'].includes(perfil) ? <NavItem to={"/eventoParticipante/" + idEvento} icon={<FaBackward size={20} />} color="outline-dark">Participantes</NavItem> : null}
                </ul>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        {dados.mostrarTermo ?
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-floating form-group">
                                                            <textarea
                                                                className="form-control form-control-lg"
                                                                id="termoaceiteassinatura"
                                                                placeholder="Termo de Aceite"
                                                                style={{ minHeight: "400px" }}
                                                                value={dados.evento.termoaceiteassinatura}
                                                                readOnly={true}
                                                            />
                                                            <label htmlFor="floatingInput">Termo de Aceite</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                        <button type='button' className="btn btn-primary me-1 mb-1"
                                                            onClick={() => {
                                                                api({
                                                                    method: 'put',
                                                                    action: '/participante/' + idParticipante,
                                                                    token: token,
                                                                    data: {
                                                                        ...{ aceitetermoassinatura: "nao" }
                                                                    }
                                                                })
                                                                    .then(function (response) {
                                                                        toast.success('Aceite registrado com sucesso!');
                                                                        dados.aceitetermoassinatura = 'nao';
                                                                        dados.mostrarTermo = false;
                                                                        //setDados(dados); //atualizar state
                                                                        window.location.reload(false);
                                                                    })
                                                                    .catch(function (error) {
                                                                        //alert(error)
                                                                    });
                                                            }}>
                                                            <FaThumbsDown size={20} /> Recusar
                                                        </button>
                                                        <button type='button' className="btn btn-primary me-1 mb-1"
                                                            onClick={() => {
                                                                api({
                                                                    method: 'put',
                                                                    action: '/participante/' + idParticipante,
                                                                    token: token,
                                                                    data: {
                                                                        ...{ aceitetermoassinatura: "sim" }
                                                                    }
                                                                })
                                                                    .then(function (response) {
                                                                        toast.success('Aceite registrado com sucesso!');
                                                                        dados.aceitetermoassinatura = 'sim';
                                                                        dados.mostrarTermo = false;
                                                                        //setDados(dados); //atualizar state
                                                                        window.location.reload(false);
                                                                    })
                                                                    .catch(function (error) {
                                                                        //alert(error)
                                                                    });
                                                            }}>
                                                            <FaThumbsUp size={20} /> Aceitar
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-floating form-group">
                                                            <label htmlFor="floatingInput" style={{ display: "contents" }}>Assinatura {dados.nome}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {dados.evento ?
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Data</label>
                                                                <ReactSelect
                                                                    value={{
                                                                        value: dados.data || "",
                                                                        label: dados.nomeData || ""
                                                                    }}
                                                                    onChange={e => {
                                                                        // Atualiza o estado
                                                                        setDados({
                                                                            ...dados,
                                                                            data: e.value,
                                                                            nomeData: e.label
                                                                        });

                                                                        // Salva no localStorage com ID do evento
                                                                        if (idEvento) {
                                                                            localStorage.setItem(`datalista_${idEvento}`, e.value);
                                                                            localStorage.setItem(`nomeDatalista_${idEvento}`, e.label);
                                                                        }
                                                                    }}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/evento/datas/' + dados.evento?.id,
                                                                            token: token,
                                                                            params: {
                                                                                nome: input,
                                                                            }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map(registro => ({
                                                                                    value: registro,
                                                                                    label: registro
                                                                                }));
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                    defaultOptions
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="form-group">
                                                                <label>Tipo Lista</label>
                                                                <ReactSelect
                                                                    id="tipoListaPresenca"
                                                                    value={{
                                                                        value: dados.tipoListaPresenca || 1,
                                                                        label: dados.nomeTipoListaPresenca || "Evento"
                                                                    }}
                                                                    onChange={(e) => {
                                                                        // Atualiza o estado
                                                                        setDados({
                                                                            ...dados,
                                                                            tipoListaPresenca: e.value,
                                                                            nomeTipoListaPresenca: e.label
                                                                        });

                                                                        // Salva no localStorage com ID do evento
                                                                        if (idEvento) {
                                                                            localStorage.setItem(`tipoListaPresenca_${idEvento}`, e.value);
                                                                            localStorage.setItem(`nomeTipoListaPresenca_${idEvento}`, e.label);
                                                                        }
                                                                    }}
                                                                    loadOptions={(input) => {
                                                                        return api({
                                                                            method: 'get',
                                                                            action: '/participanteListaPresencaTipo',
                                                                            token: token,
                                                                            params: { nome: input }
                                                                        })
                                                                            .then(response => {
                                                                                let options = response.data.data.map((registro) => {
                                                                                    return {
                                                                                        value: registro.id,
                                                                                        label: registro.nome
                                                                                    };
                                                                                });
                                                                                return options;
                                                                            })
                                                                            .catch(function (error) {
                                                                                //console.log(error);
                                                                            });
                                                                    }}
                                                                    defaultOptions
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-floating form-group">
                                                            <SignatureCanvas
                                                                canvasProps={{
                                                                    width: 300, height: 300,
                                                                    className: 'sigCanvas'
                                                                }}
                                                                ref={(ref) => {
                                                                    elementoAssinatura = ref;
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12 d-flex justify-content-end">
                                                        <button type='button' className="btn btn-primary me-1 mb-1"
                                                            onClick={() => {
                                                                elementoAssinatura.clear();
                                                            }}>
                                                            <FaTrash size={20} /> Limpar
                                                        </button>
                                                        <button type="submit" className="btn btn-primary me-1 mb-1">
                                                            <FaSave size={20} /> Salvar</button>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate>
    );
}