import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown, FaPlaneDeparture, FaPlaneArrival } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { dataMysql, extract } from '../../../util/util';
import ReactSelect from '../../../components/ReactSelect';
import NavItem from '../../../components/NavItem';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function CadastroEventoParticipanteAereoPreencherConvite({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [marcadoIda, setMarcadoIda] = useState(null);
    const [marcadoRetorno, setMarcadoRetorno] = useState(null);
    const history = useHistory();
    let { state } = useLocation();
    //var idEvento = state?.evento ?? null;

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });

            dadosRecebidos.evento.camposQuestionario = dadosRecebidos.evento.camposQuestionario ?
                JSON.parse(dadosRecebidos.evento.camposQuestionario) :
                {
                    "tipoparticipante": "sim",
                    "nome": "sim",
                    "assinaturaListaPresenca": "sim",
                    "telefone": "sim",
                    "email": "sim",
                    "especialidade": "sim",
                    "rg": "sim",
                    "crm": "sim",
                    "crmuf": "sim",
                    "datanascimento": "sim",
                    "cpf": "sim",
                    "enderecologradouro": "sim",
                    "endereconumero": "sim",
                    "enderecocomplemento": "sim",
                    "enderecocep": "sim",
                    "enderecobairro": "sim",
                    "enderecomunicipionome": "sim",
                    "enderecomunicipiouf": "sim",
                };

            setDados(dadosRecebidos);
        }).catch(err => {
            //console.log(err)
        });
    };//buscar

    const validar = () => {
        if ((dados.evento?.camposQuestionario?.bagagemDespachada ?? "nao") === "sim") {
            var bagagemDespachada = document.querySelector("#bagagemDespachada");
            if ((dados.bagagemDespachada ?? '') === '') {
                toast.error('É necessário preencher se necessita de despacho de bagagem');
                setTimeout(() => {
                    bagagemDespachada.focus();
                }, 500);
                return false;
            }
        }


        var aereoidadata = document.querySelector("#aereoidadata");
        if ((dados.aereoidadata ?? '') === '') {
            toast.error('É necessário preencher a Data de Ida');
            setTimeout(() => {
                aereoidadata.focus();
            }, 500);
            return false;
        }

        var aereoidaorigem = document.querySelector("#aereoidaorigem");
        if ((dados.aereoidaorigem ?? '') === '') {
            toast.error('É necessário preencher a Origem da Ida');
            setTimeout(() => {
                aereoidaorigem.focus();
            }, 500);
            return false;
        }

        var aereoidacompanhia = document.querySelector("#aereoidacompanhia");
        if ((dados.aereoidacompanhia ?? '') === '') {
            toast.error('É necessário preencher a Companhia Aérea da Ida');
            setTimeout(() => {
                aereoidacompanhia.focus();
            }, 500);
            return false;
        }

        var aereoidavoo = document.querySelector("#aereoidavoo");
        if ((dados.aereoidavoo ?? '') === '') {
            toast.error('É necessário preencher o Voo de Ida');
            setTimeout(() => {
                aereoidavoo.focus();
            }, 500);
            return false;
        }

        var aereoidahorasaida = document.querySelector("#aereoidahorasaida");
        if ((dados.aereoidahorasaida ?? '') === '') {
            toast.error('É necessário preencher a Hora de Saída da Ida');
            setTimeout(() => {
                aereoidahorasaida.focus();
            }, 500);
            return false;
        }

        var aereoidahorachegada = document.querySelector("#aereoidahorachegada");
        if ((dados.aereoidahorachegada ?? '') === '') {
            toast.error('É necessário preencher a Hora de Chegada da Ida');
            setTimeout(() => {
                aereoidahorachegada.focus();
            }, 500);
            return false;
        }

        var aereoidadestino = document.querySelector("#aereoidadestino");
        if ((dados.aereoidadestino ?? '') === '') {
            toast.error('É necessário preencher o Destino da Ida');
            setTimeout(() => {
                aereoidadestino.focus();
            }, 500);
            return false;
        }

        var aereoretornodata = document.querySelector("#aereoretornodata");
        if ((dados.aereoretornodata ?? '') === '') {
            toast.error('É necessário preencher a Data de Retorno');
            setTimeout(() => {
                aereoretornodata.focus();
            }, 500);
            return false;
        }

        var aereoretornoorigem = document.querySelector("#aereoretornoorigem");
        if ((dados.aereoretornoorigem ?? '') === '') {
            toast.error('É necessário preencher a Origem do Retorno');
            setTimeout(() => {
                aereoretornoorigem.focus();
            }, 500);
            return false;
        }

        var aereoretornocompanhia = document.querySelector("#aereoretornocompanhia");
        if ((dados.aereoretornocompanhia ?? '') === '') {
            toast.error('É necessário preencher a Companhia Aérea do Retorno');
            setTimeout(() => {
                aereoretornocompanhia.focus();
            }, 500);
            return false;
        }

        var aereoretornovoo = document.querySelector("#aereoretornovoo");
        if ((dados.aereoretornovoo ?? '') === '') {
            toast.error('É necessário preencher o Voo de Retorno');
            setTimeout(() => {
                aereoretornovoo.focus();
            }, 500);
            return false;
        }

        var aereoretornohorasaida = document.querySelector("#aereoretornohorasaida");
        if ((dados.aereoretornohorasaida ?? '') === '') {
            toast.error('É necessário preencher a Hora de Saída do Retorno');
            setTimeout(() => {
                aereoretornohorasaida.focus();
            }, 500);
            return false;
        }

        var aereoretornohorachegada = document.querySelector("#aereoretornohorachegada");
        if ((dados.aereoretornohorachegada ?? '') === '') {
            toast.error('É necessário preencher a Hora de Chegada do Retorno');
            setTimeout(() => {
                aereoretornohorachegada.focus();
            }, 500);
            return false;
        }

        var aereoretornodestino = document.querySelector("#aereoretornodestino");
        if ((dados.aereoretornodestino ?? '') === '') {
            toast.error('É necessário preencher o Destino do Retorno');
            setTimeout(() => {
                aereoretornodestino.focus();
            }, 500);
            return false;
        }

        return true;
    };

    const salvar = () => {
        var idEvento = dados.evento.id;

        if (!validar()) {
            return false;
        }

        setIsSubmitting(true);

        api({
            method: 'put',
            action: '/participante/escolhaAereoPreencher/' + id,
            token: token,
            data: {
                aereoidadata: dados.aereoidadata ?? null,
                aereoidaorigem: dados.aereoidaorigem ?? null,
                aereoidacompanhia: dados.aereoidacompanhia ?? null,
                aereoidavoo: dados.aereoidavoo ?? null,
                aereoidahorasaida: dados.aereoidahorasaida ?? null,
                aereoidahorachegada: dados.aereoidahorachegada ?? null,
                aereoidadestino: dados.aereoidadestino ?? null,
                aereoretornodata: dados.aereoretornodata ?? null,
                aereoretornoorigem: dados.aereoretornoorigem ?? null,
                aereoretornocompanhia: dados.aereoretornocompanhia ?? null,
                aereoretornovoo: dados.aereoretornovoo ?? null,
                aereoretornohorasaida: dados.aereoretornohorasaida ?? null,
                aereoretornohorachegada: dados.aereoretornohorachegada ?? null,
                aereoretornodestino: dados.aereoretornodestino ?? null,
                bagagemDespachada: dados.bagagemDespachada ?? null,
            }
        })
            .then(function (response) {
                if ((dados.transfer ?? 'nao') === "sim" || (dados.transferextra ?? 'nao') === "sim") {
                    history.push("/cadastroEventoParticipanteTransferConvite/" + id);
                    return;
                }
                if ((dados.hotelhospedagem ?? 'nao') === "sim") {
                    history.push("/cadastroEventoParticipanteHotelConvite/" + id);
                    return;
                }
                history.push("/cadastroEventoParticipanteConviteConcluido/" + idEvento);
            })
            .catch(function (error) {
                //alert(error)
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Escolha de aéreo</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body" style={{ padding: "0.5rem" }}>
                                            <div className="row">
                                                <h4>Ida <FaPlaneDeparture size={20} /></h4>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoidadata" placeholder="Data"
                                                            value={dados.aereoidadata}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoidadata": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Data</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoidaorigem" placeholder="Origem"
                                                            value={dados.aereoidaorigem}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoidaorigem": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Origem</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoidacompanhia" placeholder="Companhia Aérea"
                                                            value={dados.aereoidacompanhia}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoidacompanhia": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Companhia Aérea</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoidavoo" placeholder="Vôo"
                                                            value={dados.aereoidavoo}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoidavoo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Vôo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoidahorasaida" placeholder="Hora Saída"
                                                            value={dados.aereoidahorasaida}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoidahorasaida": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Hora Saída</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoidahorachegada" placeholder="Hora Chegada"
                                                            value={dados.aereoidahorachegada}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoidahorachegada": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Hora Chegada</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoidadestino" placeholder="Destino"
                                                            value={dados.aereoidadestino}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoidadestino": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Destino</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body" style={{ padding: "0.5rem" }}>
                                            <div className="row">
                                                <h4>Volta <FaPlaneArrival size={20} /></h4>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoretornodata" placeholder="Data"
                                                            value={dados.aereoretornodata}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoretornodata": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Data</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoretornoorigem" placeholder="Origem"
                                                            value={dados.aereoretornoorigem}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoretornoorigem": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Origem</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoretornocompanhia" placeholder="Companhia Aérea"
                                                            value={dados.aereoretornocompanhia}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoretornocompanhia": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Companhia Aérea</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoretornovoo" placeholder="Vôo"
                                                            value={dados.aereoretornovoo}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoretornovoo": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Vôo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoretornohorasaida" placeholder="Hora Saída"
                                                            value={dados.aereoretornohorasaida}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoretornohorasaida": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Hora Saída</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoretornohorachegada" placeholder="Hora Chegada"
                                                            value={dados.aereoretornohorachegada}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoretornohorachegada": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Hora Chegada</label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="aereoretornodestino" placeholder="Destino"
                                                            value={dados.aereoretornodestino}
                                                            onChange={e => setDados({ ...dados, ...{ "aereoretornodestino": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Destino</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(dados.evento?.camposQuestionario?.bagagemDespachada ?? "nao") === "sim" ?
                                    <div className="card">
                                        <div className="card-content">
                                            <div className="card-body" style={{ padding: "0.5rem" }}>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label>Necessita de despacho de bagagem?</label>
                                                            <ReactSelect
                                                                id={"bagagemDespachada"}
                                                                value={{ value: dados.bagagemDespachada, label: dados.nomeBagagemDespachada }}
                                                                onChange={e => setDados({ ...dados, ...{ "bagagemDespachada": e.value, "nomeBagagemDespachada": e.label } })}
                                                                options={[
                                                                    { value: "nao", label: "Não" },
                                                                    { value: "sim", label: "Sim" },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}

                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body" style={{ padding: "0.5rem" }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <h4>Informações Importantes de Aéreo</h4>
                                                    <p>
                                                        A opção de aéreo escolhida será enviada para emissão e só será emitida conforme disponibilidade. Entraremos em contato caso seja necessário validar algum ajuste.
                                                        <br />
                                                        <span style={{ fontStyle: 'italic' }}>Documentação obrigatória para essa viagem:</span>
                                                        <br />
                                                        {((dados.evento?.nacionalidade ?? "nacional") === 'nacional') ?
                                                            <strong>R.G. com emissão inferior a 10 anos ou CNH válida</strong>
                                                            :
                                                            <strong>
                                                                Passaporte com validade mínima de 6 meses. Vistos consulares dentro validade quando necessário<br />
                                                                Favor enviar copia do passaporte/RG e visto (se necessário) para o e-mail: {dados.evento?.emailremetente ?? ""}
                                                            </strong>
                                                        }
                                                    </p>
                                                    <p>
                                                        A {dados.evento?.cliente?.nomefantasia} se responsabiliza exclusivamente pelo patrocínio das passagens aéreas necessárias ao seu comparecimento no evento e período descrito no convite.<br />
                                                        Alterações serão de responsabilidade do convidado.
                                                    </p>
                                                    <p>
                                                        Favor nos confirmar se seu nome está descrito da mesma maneira que em seu documento original apresentado para embarque.<br />
                                                        Essa informação é muito importante para evitarmos transtornos no momento de seu embarque.<br />
                                                        Observações Importantes:<br />
                                                        - Informamos que qualquer tipo de alteração após a emissão do bilhete, será de total responsabilidade do participante.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1" disabled={isSubmitting}>
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div >
        </ErpTemplate >
    );
}