import React, { Component } from 'react'
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';

const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
};

export default function ReactSelect({
    key = null,
    placeholder = null,
    readOnly = false,
    isMulti = null,
    id = null,
    name = null,
    onChange = null,
    loadOptions = null,
    options = null,
    value = null,
    className = null
}) {
    if (options) {//opções fixas
        return <Select
            isMulti={isMulti}
            placeholder={placeholder}
            components={{ Placeholder }}
            styles={{
                control: (base, state) => ({
                    ...base,
                    background: "#f7faff",
                }),
            }}
            key={key}
            id={id}
            isDisabled={readOnly}
            name={name}
            value={isMulti ? value : options.filter(option => option.value === value.value)}
            onChange={onChange}
            options={options}
            isOptionDisabled={(option) => option.disabled === true}
            className={className}
        />

    } else {//opções via api
        return <AsyncSelect
            isMulti={isMulti}
            placeholder={placeholder}
            components={{ Placeholder }}
            styles={{
                control: (base, state) => ({
                    ...base,
                    background: "#f7faff",
                }),
            }}
            key={key}
            id={id}
            isDisabled={readOnly}
            name={name}
            defaultOptions
            value={value}
            onChange={onChange}
            loadOptions={loadOptions}
            isOptionDisabled={(option) => option.disabled === true}
            className={className}
        />
    }
}
