import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave, FaBackward, FaThumbsUp, FaThumbsDown, FaPlaneDeparture, FaPlaneArrival } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory, useLocation } from 'react-router-dom';
import { data, dataMysql, extract, hora } from '../../../util/util';
import ReactSelect from '../../../components/ReactSelect';
import NavItem from '../../../components/NavItem';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import moment from 'moment';

export default function CadastroEventoParticipanteHotelConvite({ match }) {
    const id = match.params.id;
    const token = localStorage.getItem('token');
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    let { state } = useLocation();
    //var idEvento = state?.evento ?? null;

    const buscar = () => {
        api({
            method: "get",
            action: '/participante/' + id,
            token: token,
        }).then(response => {
            //extrai dados internos para o array principal
            var dadosRecebidos = extract({
                jsonLaravel: response.data.data,
                chaves: [
                    'enderecopais',
                    'aereomunicipioembarque',
                    'enderecomunicipio',
                    'banco',
                ]
            });
            setDados(dadosRecebidos);
        }).catch(err => {
            //console.log(err)
        });
    };//buscar

    const validar = () => {
        return true;
    };

    const salvar = () => {
        var idEvento = dados.evento.id;

        if (!validar()) {
            return false;
        }

        setIsSubmitting(true);

        api({
            method: 'put',
            action: '/participante/escolhaHotel/' + id,
            token: token,
            data: {
                hotelobservacao: dados.hotelobservacao ?? null,
            }
        })
            .then(function (response) {
                history.push("/cadastroEventoParticipanteConviteConcluido/" + idEvento);
            })
            .catch(function (error) {
                //alert(error)
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }; //salvar

    useEffect(() => { //onload
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate sidebar={['listarsvp', 'cliente'].includes(perfil) ? false : true}>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Hospedagem</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <p>
                                                {dados.hotelnome && <strong>{dados.hotelnome}</strong>}
                                                {dados.hotellogradouro &&
                                                    <><br />
                                                        <strong>Endereço: </strong> {dados.hotellogradouro}, {dados.hotelbairro ?? ""}, {dados.hotelmunicipio?.nome ?? ""} - {dados.hotelmunicipio?.uf ?? ""}
                                                    </>
                                                }
                                                {dados.hoteldatacheckin &&
                                                    <><br />
                                                        <strong>Check-in: </strong> {data(dados.hoteldatacheckin)} {dados.hotelhoracheckin ?? ""}
                                                    </>
                                                }
                                                {dados.hoteldatacheckout &&
                                                    <><br />
                                                        <strong>Check-out: </strong> {data(dados.hoteldatacheckout)} {dados.hotelhoracheckout ?? ""}
                                                    </>
                                                }
                                                {dados.hoteltipoquarto &&
                                                    <><br />
                                                        <strong>Tipo do apartamento: </strong> {dados.hoteltipoquarto}
                                                    </>
                                                }
                                                {!dados.hotelnome &&
                                                    <><br />
                                                        <strong>Em breve enviaremos mais informações</strong>
                                                    </>
                                                }
                                            </p>
                                            {!['chiesi'].includes((dados.evento?.cliente?.modelo ?? "master")) ?
                                                <>
                                                    <p>Patrocínio disponível durante o período do evento.</p>
                                                    <p>Se você não for utilizar, ou tiver alguma dúvida sobre o serviço de hospedagem, por favor nos informe no campo abaixo:</p>
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    className="form-control form-control-lg"
                                                                    id="hotelobservacao"
                                                                    placeholder="..."
                                                                    style={{ minHeight: "100px" }}
                                                                    value={dados.hotelobservacao ?? ""}
                                                                    onChange={e => setDados({ ...dados, ...{ "hotelobservacao": e.target.value } })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-content">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1" disabled={isSubmitting}>
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div >
                    </div >
                </section >
            </div >
        </ErpTemplate >
    );
}