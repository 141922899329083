import React, { useState, useEffect } from 'react';
import ErpTemplate from '../../../components/ErpTemplate';
import { FaSave } from 'react-icons/fa';
import api from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ReactSelect from '../../../components/ReactSelect';
import { bin2hex, dataMysql, extract, pontoVirgula } from '../../../util/util';
import { toast } from 'react-toastify';
import ItemChecklistEditavel from '../../../components/ItemChecklistEditavel';

export default function CadastroEventoChecklistModelo({ match }) {
    const id = match.params.id;
    const empresa = localStorage.getItem("empresa");
    const nomeEmpresa = localStorage.getItem("nomeEmpresa");
    const [tabState, setTabState] = useState("geral");
    const token = localStorage.getItem('token');
    const idUsuario = localStorage.getItem("idUsuario");
    const nomeUsuario = localStorage.getItem("nomeUsuario");
    const perfil = localStorage.getItem("perfil");
    const [dados, setDados] = useState({});
    const [reload, setReload] = useState(1);
    const history = useHistory();

    const buscar = () => {
        if (id) {
            api({
                method: "get",
                action: '/eventoChecklistModelo/' + id,
                token: token,
            }).then(response => {
                //extrai dados internos para o array principal
                var dadosRecebidos = extract({
                    jsonLaravel: response.data.data,
                    chaves: [
                        'cliente',
                    ]
                });
                setDados(dadosRecebidos);
            }).catch(err => {
                //console.log(err)
            });
        }//if id
        else {
            dados.itens = [{
                atividade: { id: "", nome: "" },
                itens: []
            }];
            setDados({ ...dados });
        }
    };

    const validar = () => {
        /*
        var tipo = document.querySelector("#tipo");
        if (dados.tipo === undefined || dados.tipo === '') {
            toast.error('É necessário preencher o tipo da carta');
            setTimeout(() => {
                tipo.focus();
            }, 500);
            return false;
        }
        */
        return true;
    };

    const salvar = () => {
        if (!validar()) {
            return false; //para codigo aqui
        }

        if (id) {//update
            api({
                method: 'put',
                action: '/eventoChecklistModelo/' + id,
                token: token,
                data: {
                    ...dados,
                }
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/eventoChecklistModelo");
                    toast.success("As alterações foram salvas com sucesso!");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        } else {//insert
            api({
                method: 'post',
                action: '/eventoChecklistModelo',
                token: token,
                data: {
                    ...dados,
                }
            })
                .then(function (response) {
                    //console.log(response.data);
                    history.push("/eventoChecklistModelo");
                })
                .catch(function (error) {
                    toast.error('Erro: ' + (error.response?.data?.error ?? " ao carregar dados"));
                });
        }
    };

    useEffect(() => { //onload
        //document.querySelector("#nome").focus(); //autofocus no nome
        buscar();
    },
        // eslint-disable-next-line
        []);

    return (
        <ErpTemplate>
            <div className="main-content container-fluid">
                <div className="page-title">
                    <h3>Modelo de Checklist</h3>
                </div>
                <section id="multiple-column-form">
                    <div className="row match-height">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="card-body">
                                        <form className="form" onSubmit={(e) => { salvar(); e.preventDefault(); }}>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label>Cliente</label>
                                                        <ReactSelect
                                                            id="cliente"
                                                            value={{ value: dados.cliente, label: dados.nomeCliente }}
                                                            onChange={e => setDados({ ...dados, ...{ "cliente": e.value, "nomeCliente": e.label } })}
                                                            loadOptions={(input) => {
                                                                return api({
                                                                    method: 'get',
                                                                    action: '/participante',
                                                                    token: token,
                                                                    params: {
                                                                        nome: input,
                                                                        tipoCadastro: "cliente",
                                                                        situacaoRegistro: "ativo",
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        let options = response.data.data.map((registro) => {
                                                                            var label = registro.codigo +
                                                                                ' - ' + registro.nome +
                                                                                (registro.enderecomunicipio ? ' - ' + registro.enderecomunicipio.nome + '-' + registro.enderecomunicipio.uf : '') +
                                                                                (registro.cnpj ? ' (' + registro.cnpj + ')' : '') +
                                                                                (registro.cpf ? ' (' + registro.cpf + ')' : '') +
                                                                                (registro.nomefantasia ? ' - ' + registro.nomefantasia : '');
                                                                            return {
                                                                                value: registro.id,
                                                                                label: label
                                                                            };
                                                                        });
                                                                        return options;
                                                                    })
                                                                    .catch(function (error) {
                                                                        //console.log(error);
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Tipo do Evento</label>
                                                        <ReactSelect
                                                            value={{ value: dados.eventoTipo, label: dados.nomeEventoTipo }}
                                                            onChange={e => setDados({ ...dados, ...{ "eventoTipo": e.value, "nomeEventoTipo": e.label } })}
                                                            options={[
                                                                { value: 'advisoryboard', label: 'Advisory Board' },
                                                                { value: 'congresso', label: 'Congresso' },
                                                                { value: 'congressonacional', label: 'Congresso Nacional' },
                                                                { value: 'congressointernacional', label: 'Congresso Internacional' },
                                                                { value: 'congestadcotapat', label: 'Congresso - Estande e/ou Cota de Patrocínio' },
                                                                { value: 'convencaonacional', label: 'Convenção Nacional' },
                                                                { value: 'convencaointernacional', label: 'Convenção Internacional' },
                                                                { value: 'estandecotapat', label: 'Estande e/ou Cota de Patrocínio' },
                                                                { value: 'estcotpatsimpsanoeveterc', label: 'Estande e/ou Cota de Patrocínio - Simpósio Sanofi em Evento de Terceiro' },
                                                                { value: 'estcotpatcongsimpsanoeveterc', label: 'Estande e/ou Cota de Patrocínio - Congresso - Simpósio Sanofi em Evento de Terceiro' },
                                                                { value: 'eventoexclusivo', label: 'Evento Exclusivo' },
                                                                { value: 'eventointerno', label: 'Evento Interno' },
                                                                { value: 'minimeet', label: 'Mini Meeting' },
                                                                { value: 'orgsede', label: 'Organizado Pela Sede' },
                                                                { value: 'reuniaotreinamento', label: 'Reunião de Treinamento' },
                                                                { value: 'scieneducpromee', label: 'Scientific Educational Program/Meeting' },
                                                                { value: 'simposio', label: 'Simpósio' },
                                                                { value: 'simpsanofeventerc', label: 'Simpósio Sanofi em Evento de Terceiro' },
                                                                { value: 'speakmeet', label: 'Speaker Meeting (One-Time)' },
                                                                { value: 'webmeeting', label: 'Web Meeting' },
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Formato do Evento</label>
                                                        <ReactSelect
                                                            value={{ value: dados.eventoTipoRealizacao, label: dados.nomeEventoTipoRealizacao }}
                                                            onChange={e => setDados({ ...dados, ...{ "eventoTipoRealizacao": e.value, "nomeEventoTipoRealizacao": e.label } })}
                                                            options={[
                                                                { value: 'digital', label: 'Digital' },
                                                                { value: 'presencial', label: 'Presencial' },
                                                                { value: 'hibrido', label: 'Híbrido' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label>Nacionalidade do Evento</label>
                                                        <ReactSelect
                                                            id="nacionalidade"
                                                            value={{ value: dados.eventoNacionalidade, label: dados.nomeEventoNacionalidade }}
                                                            onChange={e => setDados({ ...dados, ...{ "eventoNacionalidade": e.value, "nomeEventoNacionalidade": e.label } })}
                                                            options={[
                                                                { value: 'nacional', label: 'Nacional' },
                                                                { value: 'internacional', label: 'Internacional' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-floating form-group">
                                                        <input type="text" className="form-control form-control-lg" id="nome" placeholder="Nome do Modelo"
                                                            value={dados.nome}
                                                            onChange={e => setDados({ ...dados, ...{ "nome": e.target.value } })}
                                                        />
                                                        <label htmlFor="floatingInput">Nome do Modelo</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Itens</label>
                                                        <div>
                                                            <ItemChecklistEditavel itens={dados.itens} stateChanger={setReload} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-1 mb-1">
                                                        <FaSave size={20} /> Salvar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </ErpTemplate>
    );
}